<script>
import PictureInput from 'vue-picture-input'
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Multiselect from 'vue-multiselect'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { BASE_IMAGE_URL } from '@src/config/configs'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Contents',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    PictureInput,
    vueDropzone: vue2Dropzone,
    Multiselect,

    // PageHeader
  },
  data() {
    return {
      baseImageUrl: BASE_IMAGE_URL,
      form: {
        category_name: '',
        description: '',
        image: '',
        is_coming: true,
      },
      isEdit: false,
      ismanuallyadd: false,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 380,
        maxFilesize: 10,
        maxFiles: 1,
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD ME",
      },
      options: [
        { value: true, text: 'Comming soon' },
        { value: false, text: 'Show' },
      ],
    }
  },
  validations: {
    form: {
      category_name: {
        required,
      },
      description: {
        required,
      },
    },
  },
  mounted() {
    this.initialUpdateItem()
  },
  methods: {
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    vfileremove() {
      this.form.image = ''
    },
    vfilemanuallyAdded(file) {
      this.ismanuallyadd = true
    },
    vfileAdded(file) {
      this.form.image = file
      this.ismanuallyadd = false
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async formSubmit() {
      //   this.$v.form.$touch()
      //   if (this.$v.form.$anyError) {
      //     return
      //   }

      if (!this.form.image) {
        this.$toast.error('Image field must not be empty ')
        return
      }

      let body = {
        category_name: this.form.category_name,
        description: this.form.description,
        is_coming: this.form.is_coming,
      }
      const data = new FormData()
      if (!this.ismanuallyadd) {
        data.append('image', this.form.image)
      }

      data.append('data', JSON.stringify(body))
      const queryId = this.$route.query.id
      const request = !this.isEdit
        ? axios.post('/api/category', data)
        : axios.patch(`/api/category/${queryId}`, data)

      // await axios
      //   .post('/api/category', data)
      //   .then((result) => {
      //     this.$router.push('/manage/category')
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     alert(err.response.data.error.message)
      //   })

      await request
        .then((result) => {
          this.$toast.success('Saved successfully')
          this.$router.back()
        })
        .catch((err) => {
          this.$toast.error(err.response.data.error.message)
        })
    },

    async initialUpdateItem() {
      const id = this.$route.query.id
      if (!id) return

      await axios.get(`/api/category/${id}`).then((res) => {
        this.form = { ...this.form, ...res.data }
        this.isEdit = true

        var file = { size: 123, name: 'Icon', type: 'image/JPG' }
        var url = `${this.baseImageUrl}/${this.form.category_image}`
        this.$refs.myVueDropzone.manuallyAddFile(file, url)
      })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">Create Category Courses</h4>
      </div>
      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <b-form class="form-horizontal" @submit.prevent="formSubmit">
                <div class="row center">
                  <div class="col-7">
                    <label>
                      Image
                      <small class="color-text-small"
                        >Width 750 x Height 340. Just only one.</small
                      >
                    </label>

                    <b-form-group label-cols-lg="0">
                      <vue-dropzone
                        @vdropzone-file-added="vfileAdded"
                        @vdropzone-file-added-manually="vfilemanuallyAdded"
                        @vdropzone-removed-file="vfileremove"
                        id="image"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                      ></vue-dropzone>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Title</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter content title..."
                        name="title"
                        type="text"
                        v-model="form.category_name"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <!-- :state="validateState('category_name')" -->
                      <b-form-invalid-feedback id="input-1-live-feedback"
                        >This is a required
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Description</label>
                      <b-form-input
                        id="title"
                        placeholder="Enter content title..."
                        name="title"
                        type="text"
                        v-model="form.description"
                        aria-describedby="input-2-live-feedback"
                      ></b-form-input>
                      <!-- :state="validateState('description')" -->
                      <b-form-invalid-feedback id="input-2-live-feedback"
                        >This is a required field
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group label-cols-lg="0">
                      <label>Status</label>

                      <div>
                        <b-form-select
                          v-model="form.is_coming"
                          :options="options"
                        ></b-form-select>
                      </div>
                    </b-form-group>

                    <b-form-group class="pt-5">
                      <button
                        type="button"
                        class="btn btn-danger width-lg rounded-pill aligncenter float-left"
                        @click="
                          () => {
                            this.$router.back()
                          }
                        "
                      >
                        <feather type="x"></feather>Cancel
                      </button>

                      <button
                        type="submit"
                        class="btn btn-primary width-lg rounded-pill aligncenter float-right"
                      >
                        <feather type="file-plus"></feather>
                        <span>{{
                          this.$route.query.id ? 'save' : 'Create'
                        }}</span>
                      </button>
                    </b-form-group>
                  </div>
                </div>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
